<template>
  <admin>
    <metatag title="Survey Ranking"></metatag>
    <page-header>
      <template #title> DCSI Survey Ranking </template>
      <template #action>
        <v-btn
          :block="$vuetify.breakpoint.smAndDown"
          large
          color="grey"
          class="white--text"
          exact
          @click="download"
          :loading="downloadLoading"
          :disabled="downloadLoading"
        >
          <v-icon small left>mdi-cloud-download</v-icon>
          <span v-text="'Download'"></span>
        </v-btn>
      </template>
    </page-header>

    <v-card>
      <can :code="['admin', 'admin_survey']">
        <survey-filter
          ref="toolbar"
          :advanceFilter="true"
          :filterSearch="false"
          :filterDateRange="true"
          :filterRegions="true"
          :filterGroups="true"
          :filterZones="true"
          :filterDealer="true"
          :isSearchColsAuto="true"
          :items.sync="tabletoolbar"
          :bottomdivider="false"
          :start-end-date="filters.date"
          @update:daterangesurvey="setDateRange"
          @update:regions="setRegions"
          @update:zones="setZones"
          @update:groups="setGroups"
          @update:dealer="setDealer"
        />
      </can>

      <can :code="['dealer_survey', 'dealer']">
        <survey-filter
          ref="toolbar"
          :advanceFilter="false"
          :filterDateRange="true"
          :filterRegions="true"
          :filterGroups="true"
          :filterZones="true"
          :filterDealer="true"
          :isSearchColsAuto="true"
          :items.sync="tabletoolbar"
          :bottomdivider="false"
          :start-end-date="filters.date"
          @update:daterangesurvey="setDateRange"
          @update:regions="setRegions"
          @update:zones="setZones"
          @update:groups="setGroups"
          @update:dealer="setDealer"
        />
      </can>

      <v-data-table
        :headers="resources.headers"
        :items="dealerSurveyDetails"
        :items-per-page="5"
        :server-items-length="resources.total"
        @update:options="handleOptions"
      >
        <template v-slot:item.rank="{ item }">
          <v-btn text color="primary" icon>
            {{ item.rank }}
          </v-btn>
        </template>

        <template v-slot:item.dealer_name="{ item }">
          <span
            class="font-weight-bold"
            v-if="
              parseInt(item.respondents_count) < parseInt(item.target_count_limit) ||
              haveStaticAsterisk(item)
            "
            >*</span
          >
          {{ item.dealer_name }}
          <br />
        </template>

        <template v-slot:item.respondents_ratio="{ item }">
          {{ item.respondents_ratio }}%
        </template>

        <template v-slot:item.created_at="{ item }">
          <span>{{ formatDate(item.created_at) }}</span>
        </template>

        <template v-slot:item.action="{ item }">
          <!-- View Details -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn text icon v-on="on" @click="showSurveyResult(item)">
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span v-text="'View Details'"></span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <template v-if="previewDialog">
      <v-dialog v-model="previewDialog" max-width="900px" class="overflow-hidden">
        <v-card class="pa-4">
          <v-card-title>
            <h2 title="Survey Result" class="mb-1">
              {{ dialogItemQuestions.dealer_name }}
            </h2>
          </v-card-title>
          <v-card-text class="overflow-y-auto">
            <!-- Background Details -->
            <div class="mb-5" v-for="question in dialogItemQuestions.questions_data">
              <h3>{{ question.factor_title }}</h3>
              <v-simple-table>
                <tbody>
                  <tr v-for="questionItem in question.questions">
                    <td width="70%">
                      <small>{{ questionItem.question_title }}</small>
                    </td>
                    <td width="30%">{{ questionItem.score }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
            <!-- Background Details -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              color="grey"
              exact
              class="ma-1 white--text px-5"
              @click="previewDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </admin>
</template>

<script>
import apiDealerService from "@/services/api/modules/dealerService";
import apiSurveysService from "@/services/api/modules/surveysService";
import * as helpers from "@/core/helpers";
import { mapActions, mapGetters } from "vuex";
import { debounce } from "lodash";
import moment from "moment";

export default {
  data: () => ({
    tabletoolbar: {
      isSearching: false,
      search: null,
      toggleBulkEdit: false,
      bulkCount: 0,
      actionDropdownItems: [
        {
          slug: "activate",
          action: "Activate",
        },
        {
          slug: "deactivate",
          action: "Deactivate",
        },
        {
          slug: "delete",
          action: "Delete",
        },
      ],
    },

    downloadLoading: false,
    items: [
      {
        text: "CS Dashboard",
        disabled: false,
        href: "cs-dashboard",
        routeName: "survey-reports.cs-dashboard",
      },
      {
        text: "Survey Ranking",
        disabled: false,
        href: "survey-ranking",
        routeName: "survey-reports.survey-ranking",
      },
      {
        text: "Survey Summary",
        disabled: false,
        href: "survey-summary",
        routeName: "survey-reports.survey-summary",
      },
      {
        text: "CDC Summary",
        disabled: false,
        href: "cdc-summary",
        routeName: "survey-reports.cdc-summary",
      },
      {
        text: "Dealer Action Plan",
        disabled: false,
        href: "dealer-action-plan",
        routeName: "survey-reports.dealer-action-plan",
      },
    ],
    dealers: [],
    headers: [],
    headers2: [],
    defaultHeaders: [
      { label: "Dealer", sortable: true, sort: "desc" },
      { label: "Rank" },
      { label: "Respondents" },
      { label: "Respondent Ratio" },
      { label: "CSI Score" },
      // { label: 'Details' }
    ],

    previewDialog: false,
    dialogItem: {},
    dialogItemQuestions: [],
    resources: {
      headers: [
        {
          text: "Rank",
          align: "center",
          value: "rank",
        },
        {
          text: "Dealer",
          align: "center",
          value: "dealer_name",
        },
        {
          text: "Respondents",
          align: "center",
          value: "respondents_count",
        },
        {
          text: "Respondent Ratio",
          align: "center",
          value: "respondents_ratio",
        },
        {
          text: "CSI Score",
          align: "center",
          value: "csi_score",
        },
        {
          text: "Action",
          align: "left",
          value: "action",
        },
      ],
      total: 0,
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 5,
      },
    },
    filters: {
      date: [],
      region: null,
      zone: null,
      group: null,
      dealer: null,
    },
    orderBy: "csi_score",
    sortDir: "DESC",
    surveys: [],
    survey: {},
    dealerSurveyDetails: [],
  }),
  watch: {
    "resources.options": {
      handler() {
        // const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
        // this.orderBy = sortBy.length ? sortBy[0] : "csi_score";
        // this.sortDir = !sortDesc[0] ? "DESC" : "ASC";
        // this.getDealers({
        //   page,
        //   per_page: itemsPerPage,
        //   dates: this.filters.date,
        // });
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      getDealerZones: "dealerZone/getDealerZones",
      getDealers2: "dealer/getDealers",
    }),
    setDateRange(e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      this.orderBy = sortBy.length ? sortBy[0] : "csi_score";
      this.sortDir = !sortDesc[0] ? "DESC" : "ASC";
      this.resources.options.page = 1;

      this.filters.date = e;

      const self = this;

      self.getDealers({
        page: 1,
        per_page: itemsPerPage,
        dates: self.filters.date,
        region: self.filters.region,
        zone: self.filters.zone,
        group: self.filters.group,
        dealer: self.filters.dealer,
      });
    },

    async setRegions(e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      this.orderBy = sortBy.length ? sortBy[0] : "csi_score";
      this.sortDir = !sortDesc[0] ? "DESC" : "ASC";
      this.resources.options.page = 1;

      this.filters.region = e;

      const self = this;

      self.getDealers({
        page: 1,
        per_page: itemsPerPage,
        dates: self.filters.date,
        region: self.filters.region,
        zone: self.filters.zone,
        group: self.filters.group,
        dealer: self.filters.dealer,
      });

      await this.getDealerZones([this.filters.region]);

      await this.getDealers2({
        region_types: this.filters.region ? [this.filters.region] : [],
        zones: this.filters.zone ? [this.filters.zone] : [],
        groups: this.filters.group ? [this.filters.group] : [],
      });
    },

    setZones(e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      this.orderBy = sortBy.length ? sortBy[0] : "csi_score";
      this.sortDir = !sortDesc[0] ? "DESC" : "ASC";
      this.resources.options.page = 1;

      this.filters.zone = e;

      const self = this;

      self.getDealers({
        page: 1,
        per_page: itemsPerPage,
        dates: self.filters.date,
        region: self.filters.region,
        zone: self.filters.zone,
        group: self.filters.group,
        dealer: self.filters.dealer,
      });
    },

    setGroups(e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      this.orderBy = sortBy.length ? sortBy[0] : "csi_score";
      this.sortDir = !sortDesc[0] ? "DESC" : "ASC";
      this.resources.options.page = 1;

      this.filters.group = e;

      const self = this;

      self.getDealers({
        page: 1,
        per_page: itemsPerPage,
        dates: self.filters.date,
        region: self.filters.region,
        zone: self.filters.zone,
        group: self.filters.group,
        dealer: self.filters.dealer,
      });
    },

    setDealer(e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      this.orderBy = sortBy.length ? sortBy[0] : "csi_score";
      this.sortDir = !sortDesc[0] ? "DESC" : "ASC";
      this.resources.options.page = 1;

      this.filters.dealer = e;

      const self = this;

      self.getDealers({
        page: 1,
        per_page: itemsPerPage,
        dates: this.filters.date,
        region: this.filters.region,
        zone: this.filters.zone,
        group: this.filters.group,
        dealer: this.filters.dealer,
      });
    },

    showSurveyResult(item) {
      this.dialogItemQuestions = item;
      this.previewDialog = true;
    },

    async getDealers(param = {}) {
      if (this.orderBy == "rank") {
        this.orderBy = "csi_score";
      }

      await apiSurveysService
        .getSurveyDcsiRanking(
          param.page,
          param.per_page,
          param.dates,
          param.region,
          param.zone,
          param.group,
          param.dealer,
          this.orderBy,
          this.sortDir
        )
        .then((response) => {
          this.dealers = response.data.data;
          this.dealerSurveyDetails = response.data.data;
          this.resources.total =
            param.per_page > 0 ? response.data.meta.total : response.data.data.length;
        })
        .catch((e) => {
          this.dealers = [];
          console.log(e);
        });
    },
    toggleHeaderSortIcon(header) {
      header.sort = header.sort === "asc" ? "desc" : "asc";
      return header;
    },
    download() {
      const self = this;

      this.downloadLoading = true;

      setTimeout(() => {
        apiSurveysService
          .downloadDcsiReport(
            self.filters.date,
            self.filters.region,
            self.filters.zone,
            self.filters.group,
            self.filters.dealer
          )
          .then((response) => {
            window.location = response.data.data.url;
            this.downloadLoading = false;
          });
      }, 1000);
    },
    setYesterdayDate() {
      let today = new Date();
      let yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      const yesterDate = helpers.format_date(yesterday, "YYYY-MM-DD");

      this.filters.date = [yesterDate, yesterDate];

      const { itemsPerPage } = this.resources.options;
      this.getDealers({
        page: 1,
        per_page: itemsPerPage,
        dates: this.filters.date,
      });
    },
    handleOptions(options) {
      this.resources.options = options;

      if (this.filters.date.length > 0) {
        this.orderBy = options.sortBy.length ? options.sortBy[0] : "csi_score";
        this.sortDir = !options.sortDesc[0] ? "DESC" : "ASC";

        this.getDealers({
          page: options.page,
          per_page: options.itemsPerPage,
          dates: this.filters.date,
        });
      }
    },
    haveStaticAsterisk(dealer) {
      if (this.filters.date.length > 1) {
        const startDate = moment(this.filters.date[0]).format("YYYY-MM");
        const endDate = moment(this.filters.date[1]).format("YYYY-MM");
        const dealerName = ["HONDA CARS NUEVA ECIJA"];

        if (
          startDate == "2023-04" &&
          endDate == "2023-09" &&
          (dealer.respondents_ratio <= 10 || dealerName.includes(dealer.dealer_name))
        ) {
          return true;
        }

        return false;
      }
    },
  },
  mounted() {
    this.setYesterdayDate();
  },
};
</script>
